<template>
  <div class="text-center title">
    <a href="/"><img src="../../assets/logo.png" class="w-48 m-auto py-2 text-center cursor-pointer" /></a>
  </div>
</template>
<script>
export default {
  name: 'Header',
}
</script>
<style scoped>
  .title {
    background-color: #353540;
  }
</style>
