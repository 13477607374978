<template>
    <div class="m-2 relative shadow-lg rounded">
      <img class="rounded w-full h-full" :src="url"/>
    </div>    
</template>
<script>
export default {
  name: 'Screenshot',
  props: ['url'],

}
</script>
