<template>
  <Header />
  <div class="xl:mx-96 xl:mt-8">
    <img src="img/tinyjukebox-banner.png" />
  </div>

  <div class="xl:mx-96 info text-white text-lg p-8">
    Leonie, a tiny jukebox who can only play 10 seconds songs, forgot how to play her songs. Help her by playing the notes in time! 
    <br /><br />
    Try to complete the 70+ songs without losing life in this roguelike rythm game 🙃 

    <div class="grid grid-cols-3 md:grid-cols-3 lg:grid-cols-3 my-1">
      <Screenshot url="img/tinyjukebox1.png" />
      <Screenshot url="img/tinyjukebox2.png" />
      <Screenshot url="img/tinyjukebox3.png" />
    </div>
    <p class="text-2xl mb-4">PLAY HERE:</p>
    <div class="my-1 text-left text-xl">
      <p><a href="https://store.steampowered.com/app/2716970/Tiny_Jukebox/"><i class="fa-solid fa-caret-right mr-2"></i>Steam</a></p>
      <p><a href="https://flocons.itch.io/tiny-jukebox-complete"><i class="fa-solid fa-caret-right mr-2"></i>Itch.io</a></p>
      <p><a href="https://www.coolmathgames.com/0-tiny-jukebox"><i class="fa-solid fa-caret-right mr-2"></i>Cool Math Games</a></p>
      <p><a href="https://www.newgrounds.com/portal/view/858965"><i class="fa-solid fa-caret-right mr-2"></i>Newgrounds</a></p>
      <p><a href="https://www.kongregate.com/games/grizzlycogs/tiny-jukebox"><i class="fa-solid fa-caret-right mr-2"></i>Kongregate</a></p>
    </div>
  </div>
</template>
<script>
import Header from '../home/Header'
import Screenshot from '../home/Screenshot'

export default {
  name: 'Tiny Jukebox',
  components: { Header, Screenshot }
}
</script>
<style scoped>
  .info {
    background-color: #353540;
  }
</style>