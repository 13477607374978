<template>
    <div class="m-2 relative shadow-lg rounded border-green-400" @mouseenter="hover = true" @mouseleave="hover = false">
      <a :href="url">
      <img class="rounded w-full h-full" :src="cover" v-on:click="show = true"/>
      <transition name="fade">
        <div v-if="hover" class="cursor-pointer absolute top-0 left-0 w-full h-full info text-white rounded hidden lg:block">
          <div class="relative top-8">
            <p class="tracking-wider text-xl text-white">{{ name }}</p>
          </div>
          <div class="relative top-24 text-3xl ">
              <i class="fas fa-info-circle cursor-pointer"></i>
          </div>
        </div>
      </transition>
      </a>
    </div>    
</template>
<script>

export default {
  name: 'MiniGame',
  props: ['name', 'url', 'cover'],
  methods: {
  },
  data: function
    MusicPlayer() {
    return { hover: false, show: false }
  },
}
</script>
<style scoped>
  .fade-enter-active, .fade-leave-active {
    transition: opacity .5s;
  }

  .fade-enter-from, .fade-leave-to {
    opacity: 0;
  }

  .info {
    background-color: #353540;
  }

</style>